import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};

export default {
    loadData: async function () {

        let data = await axios.get(NetworkUtils.HOST + "/unlock-support-settings", config);

        if (data.data.length > 0) {
            data = data.data[0].list;
        } else {
            //console.log('ERROR WHILE LOADING RATIOS');
        }
        return data;
    },

}