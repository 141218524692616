import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../store";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};

export default {

    loadData : async function() {

        //let questList = [];

        //let data = await axios.get("http://139.59.234.91/skills?_limit=1&_sort=id:DESC");
        let data = await axios.get(NetworkUtils.HOST + '/skills?_limit=1&_sort=id:DESC', config);
        //console.log('did received call AXIOS');
        //console.log(data.data);

        data = {data:[]};

        if (data.data.length > 0) {
            //console.log('WILL LOAD HERO SKILL FROM SERVER');
            data = data.data[0].list;
        }else {

            //console.log('WILL LOAD THE HERO SKILL FROM LOCAL FILES');
            let jsonStruct = require('../../legacy/UserSkillData.json');




            //additional mappin
            //mapping the name
            let hero_active_skill_names = [];
            hero_active_skill_names[0]              = "Thunder attack";
            hero_active_skill_names[1]              = "Twins shadow";
            hero_active_skill_names[2]              = "Last hand";
            hero_active_skill_names[3]              = "Fast and furious";
            hero_active_skill_names[4]              = "Anger of gods";
            hero_active_skill_names[5]              = "Gold fingers";
            hero_active_skill_names[6]              = "Pets Alliance";
            hero_active_skill_names[7]              = "Shield of god";
            hero_active_skill_names[8]              = "Flying supporters";
            hero_active_skill_names[9]              = "Fear bunny ears";

            jsonStruct.forEach(skill => {

                skill.fullName = hero_active_skill_names[skill.m_iID]
                skill.isBoss = skill.availableType === 2;

            })

            data = {data:jsonStruct};


            //console.log(data);

        }
        //console.log('END OF DAO PROCESS HERO SKILL: ')
        //console.log(data);
        return data.data;
    }
}
