import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store";
const authorize =  `Bearer ${store.getters.getToken}`;

const config = {
    headers: { Authorization: authorize }
};

export default {

    loadEnemyData : async function() {
        let data = await axios.get(NetworkUtils.HOST + '/enemy-data', config);
        let enemysData = [];
        let enemysZoneData = [];
        if (data.data.length > 0) {
            enemysData = data.data[0].enemysData;
            enemysZoneData = data.data[0].enemysZoneData;
        }

        if(enemysData.length < 1){
            enemysData = await this.loadEnemyDataLocal();
            await this.pushEnemysData(enemysData);
        }

        if(enemysZoneData.length < 1){
            enemysZoneData = await this.loadEnemyZoneDataLocal();
            await this.pushEnemysZoneData(enemysZoneData);
        }

        return {
            enemysData: enemysData,
            enemysZoneData: enemysZoneData
        };
    },

    loadEnemyDataLocal : async function() {
        let data = require('../assets/json/enemysdata.json');
        return data;
    },

    loadEnemyZoneDataLocal : async function() {
        let data = require('../assets/json/enemysdatabyzone.json');
        return data;
    },

    async pushEnemysData(enemysData) {
        let persistedList = await axios.get(NetworkUtils.HOST + "/enemy-data", config);
        if (persistedList.data && persistedList.data.length > 0) {
            await axios.put(NetworkUtils.HOST + "/enemy-data/" + persistedList.data[0]._id, {enemysData: enemysData}, config);

        } else {
            await axios.post(NetworkUtils.HOST + "/enemy-data", {enemysData: enemysData},config);
        }
    },

    async pushEnemysZoneData(enemysZoneData) {
        let persistedList = await axios.get(NetworkUtils.HOST + "/enemy-data", config);
        if (persistedList.data && persistedList.data.length > 0) {
            await axios.put(NetworkUtils.HOST + "/enemy-data/" + persistedList.data[0]._id, {enemysZoneData: enemysZoneData}, config);

        } else {
            await axios.post(NetworkUtils.HOST + "/enemy-data", {enemysZoneData: enemysZoneData},config);
        }
    },
}