import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};

export default {
    loadRefData : async function() {
        let listData = require('../constants/UGSGameVersionConfigData')
        return listData;
    },
    loadGameVersion : async function() {
        let data = await axios.get(NetworkUtils.HOST + '/gameversion', config);
        if (data.data.length > 0) {
            data = data.data[0].list;
            return data;
        } else {
            data = await this.loadRefData();
            return  data;
        }
    },
}