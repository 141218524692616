module.exports=[{
    "_id": "602e48494c19289b6e5dba9c",
    "versionCode": "1.0.0",
    "versionValue": 100,
    "forceUpdate": true,
    "shouldNotifyAboutUpdateAvailable": true,
    "description": "",
    "id": "602e48494c19289b6e5dba9c",
    "buildNumber": 20,
    "descriptionEn": "",
    "descriptionFr": "",
    "descriptionKh": "",
    "descriptionSp": "",
    "link": "https://play.google.com/store/apps/details?id=com.sompom.puzzle.match.adventure.monsters",
    "platform": "Android"
}, {
    "_id": "605dc8364c19289b6e7dcabd",
    "versionCode": "1.0.7",
    "versionValue": 107,
    "forceUpdate": true,
    "shouldNotifyAboutUpdateAvailable": true,
    "description": "",
    "id": "605dc8364c19289b6e7dcabd",
    "buildNumber": 67,
    "descriptionEn": "",
    "descriptionFr": "",
    "descriptionKh": "",
    "descriptionSp": "",
    "link": "https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewContentsUserReviews?id=1091960385&pageNumber=0&sortOrdering=1&type=Purple+Software&mt=8",
    "platform": "iOS"
}]