/* not load from backend any more
import axios from "axios";
import NetworkUtils from "../NetworkUtils";
import store from "../store";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};*/

let progress_des = [];

progress_des[0] = "First Tap!";
progress_des[1] = "Tap [n] times in [t] second!";
progress_des[2] = "Tap [n] times!";
progress_des[5] = "Unlock [n] more supports!";
progress_des[6] = "Collect [n] gold!";
progress_des[7] = "Update hero level to [n]!";
progress_des[8] = "Reach stage [n]!";
progress_des[9] = "Kill [n] ghosts!";
progress_des[10] = "Defeat [n] bosses of battle!";
progress_des[11] = "Defeat [n] bosses of revenge!";
progress_des[12] = "Open chest [n] times";
progress_des[13] = "Watch the video [n] times!";
progress_des[14] = "Collect [n] pets!";
progress_des[15] = "Collect [n] gold in [t] second!";
progress_des[16] = "Hire [n] heroes!";
progress_des[17] = "Come back [n] days consecutively";
progress_des[18] = "Upgrade all heroes to level [n2]";
progress_des[19] = "Upgrade all pets to level [n2]";
progress_des[20] = "Upgrade [n] pets to level [n2]";
progress_des[21] = "Upgrade [n] heroes to level [n2]";
progress_des[23] = "Collect bird diamond [n] times";
progress_des[24] = "Make [n] heroes revive";
progress_des[25] = "Make [n] supports revive";
progress_des[26] = "Pass [n] boss fight consecutivly";
progress_des[27] = "Combine [n] powerup in the same time";
progress_des[28] = "Play the game on weekend";
progress_des[29] = "Use the shield [n] times";
progress_des[30] = "Play the game [n] times";
progress_des[31] = "Full support";
progress_des[32] = "Full active support";
progress_des[33] = "Full flying support";
progress_des[34] = "Hire a full team of 8 heroes";
progress_des[35] = "Full team!";
progress_des[36] = "First Time world Loop!";
progress_des[37] = "Make [n] world tour of hokmok!";
progress_des[38] = "Obtain your first powerup!";
progress_des[39] = "Complete your first mission!";
progress_des[40] = "Complete your [n] mission!";
progress_des[41] = "Collect [n] diamonds!";
progress_des[42] = "Complete [n]th Stage";
progress_des[43] = "Beat [n] levels";
progress_des[44] = "Clear [n] boss monsters";
progress_des[45] = "Tap [n] critical tap in [t] seconds";
progress_des[46] = "Level heroes [n] times";
progress_des[47] = "Level supports [n] times";
progress_des[48] = "Unlock [n] support skills";
progress_des[49] = "Upgrade power-up [n] times";
progress_des[50] = "Start using mana";
progress_des[51] = "Make [n] combo hits";
progress_des[52] = "Swipe [n2] runes in one horizontal [n] times";
progress_des[53] = "Swipe [n2] runes in one vertical [n] times";
progress_des[54] = "[n] minutes total play time";
progress_des[56] = "[n] minutes daily playing";
progress_des[57] = "Complete [n] Levels in total";
progress_des[58] = "Use perk Give me cash once!";
progress_des[59] = "Use perk Doom once!";
progress_des[60] = "Use Perk Power of Holding once";
progress_des[61] = "Use perk Mana Potion once";
progress_des[62] = "Train [n] hero power-up!";
progress_des[63] = "Unlock [n] heroe power-up!";
progress_des[64] = "Obtain [n] power-up and use it!";
progress_des[65] = "Promote your hero to the bronze!";
progress_des[66] = "Promote your hero to the silver!";
progress_des[67] = "Promote your hero to the gold!";
progress_des[68] = "Promote your hero to the platinium!";
progress_des[69] = "Promote [n] heroes to the bronze!";
progress_des[70] = "Promote [n] heroes to the silver!";
progress_des[71] = "Promote [n] heroes to the gold!";
progress_des[72] = "Promote [n] heroes to the platinium!";
progress_des[73] = "Collect [n] boss crowns!";
progress_des[74] = "Fail [n] times!";
progress_des[75] = "Obtain [n] bird gifts in 1 week!";
progress_des[76] = "Collect first boss crown!";
progress_des[77] = "Update a support to [n]!";
progress_des[78] = "Update a pet!";
progress_des[79] = "Update a pet [n] times!";
progress_des[80] = "Swipe [n2] runes in one row [n] times";
progress_des[81] = "[n] hour total play time";
progress_des[82] = "[n] day total play time";
progress_des[83] = "Share the game to Facebook";
progress_des[84] = "Share the game to Twitter";
progress_des[85] = "Use perk Give me cash [n] times";
progress_des[86] = "Use perk Doom [n] times";
progress_des[87] = "Use Perk Power of Holding [n] times";
progress_des[88] = "Use perk Mana Potion [n] times";
progress_des[89] = "Critical Hits [n] times";
progress_des[90] = "Update [n] pets!";
progress_des[91] = "Use [n2] times [n] perks!";
progress_des[92] = "Use [n2] times [n] power-up!";
progress_des[93] = "Reduce ghost round [n] times!";
progress_des[94] = "Reach Threshold of [n] DPS";
progress_des[95] = "[support_name] evolve [n] times!";
progress_des[96] = "[hero_name] reach rank [rank_title]";
progress_des[97] = "[support_name] reach rank [rank_title]";
progress_des[98] = "Use [n] [fruit_type] magic runes";
progress_des[99] = "Make [n] combos hits against a [boss_type]";
progress_des[100] = "Play [n] mastermind";
progress_des[101] = "Reach [n] mastermind color in a row ";
progress_des[102] = "Win [n] mastermind";
progress_des[103] = "Play [n] mastermind during the day";
progress_des[104] = "Make [n] questImpl";
progress_des[105] = "Use [powerup_name] [n] times";
progress_des[106] = "Revive [n] times a support";
progress_des[107] = "Revive [n] times a hero";
progress_des[108] = "Level up your [hero_name] [n] times";
progress_des[109] = "Level up your [support_name] [n] times";
progress_des[110] = "Level up your [pet_name] [n] times";
progress_des[111] = "All supports reach evolve [n]";
progress_des[112] = "all heroes reach rank [rank_title]";
progress_des[113] = "Kill [n] times boss";
progress_des[114] = "Kill [n] times big boss";
progress_des[115] = "Unlock \"[world_name]\"";
progress_des[116] = "Revive all time [n] times";


let progress_titles = [];

progress_titles[0] = "First Tap!";
progress_titles[1] = "Tap";
progress_titles[2] = "Tap";
progress_titles[5] = "Hire Support!";
progress_titles[6] = "Collect gold!";
progress_titles[7] = "Update hero!";
progress_titles[8] = "Reach stage!";
progress_titles[9] = "Kill ghosts!";
progress_titles[10] = "Defeat bosses!";
progress_titles[11] = "Defeat bosses!";
progress_titles[12] = "Open chest";
progress_titles[13] = "Watch the video!";
progress_titles[14] = "Collect pets!";
progress_titles[15] = "Collect gold!";
progress_titles[16] = "Hire heroes!";
progress_titles[17] = "Come back";
progress_titles[18] = "Upgrade heroes";
progress_titles[19] = "Upgrade pets";
progress_titles[20] = "Upgrade pets";
progress_titles[21] = "Upgrade heroes";
progress_titles[23] = "Collect diamond";
progress_titles[24] = "Make revive";
progress_titles[25] = "Make revive";
progress_titles[26] = "Pass boss fight";
progress_titles[27] = "Combine powerup";
progress_titles[28] = "On weekend";
progress_titles[29] = "Use the shield";
progress_titles[30] = "Play the game";
progress_titles[31] = "Full support";
progress_titles[32] = "Active support";
progress_titles[33] = "Flying support";
progress_titles[34] = "Full team";
progress_titles[35] = "Full team!";
progress_titles[36] = "World Loop!";
progress_titles[37] = "World tour!";
progress_titles[38] = "First powerup!";
progress_titles[39] = "First mission!";
progress_titles[40] = "Your 10 mission!";
progress_titles[41] = "Collect diamonds!";
progress_titles[42] = "Complete Stage";
progress_titles[43] = "Beat levels";
progress_titles[44] = "Clear boss";
progress_titles[45] = "Critical tap";
progress_titles[46] = "Level heroes";
progress_titles[47] = "Level supports";
progress_titles[48] = "Support skills";
progress_titles[49] = "Upgrade power-up";
progress_titles[50] = "Using mana";
progress_titles[51] = "Combo hits";
progress_titles[52] = "Swipe horizontal";
progress_titles[53] = "Swipe vertical";
progress_titles[54] = "Play time";
progress_titles[56] = "Daily playing";
progress_titles[57] = "Levels in total";
progress_titles[58] = "Use perk!";
progress_titles[59] = "Use perk!";
progress_titles[60] = "Use Perk!";
progress_titles[61] = "Use perk!";
progress_titles[62] = "Train power-up!";
progress_titles[63] = "Unlock power-up!";
progress_titles[64] = "Obtain power-up!";
progress_titles[65] = "Promote hero!";
progress_titles[66] = "Promote hero!";
progress_titles[67] = "Promote hero!";
progress_titles[68] = "Promote hero!";
progress_titles[69] = "Promote hero!";
progress_titles[70] = "Promote hero!";
progress_titles[71] = "Promote hero!";
progress_titles[72] = "Promote hero!";
progress_titles[73] = "Collect crowns!";
progress_titles[74] = "Fail [n] times!";
progress_titles[75] = "Obtain gifts!";
progress_titles[76] = "First crown!";
progress_titles[77] = "Update support!";
progress_titles[78] = "Update pet!";
progress_titles[79] = "Update pet!";
progress_titles[80] = "Swipe runes";
progress_titles[81] = "Play time";
progress_titles[82] = "Play time";
progress_titles[83] = "Facebook";
progress_titles[84] = "Twitter";
progress_titles[85] = "Use perk!";
progress_titles[86] = "Use perk!";
progress_titles[87] = "Use Perk!";
progress_titles[88] = "Use perk!";
progress_titles[89] = "Critical Hits";
progress_titles[90] = "Update pets!";
progress_titles[91] = "Use perks!";
progress_titles[92] = "Use power-up!";
progress_titles[93] = "Reduce round!";
progress_titles[94] = "Reach DPS";
progress_titles[95] = "Support evolve!";
progress_titles[96] = "Reach rank";
progress_titles[97] = "Reach rank";
progress_titles[98] = "Magic runes";
progress_titles[99] = "Combos hits";
progress_titles[100] = "Play mastermind";
progress_titles[101] = "Reach mastermind";
progress_titles[102] = "Win mastermind";
progress_titles[103] = "During the day";
progress_titles[104] = "Make questImpl";
progress_titles[105] = "[powerup_name]";
progress_titles[106] = "Revive support";
progress_titles[107] = "Revive hero";
progress_titles[108] = "Level up";
progress_titles[109] = "Level up Support";
progress_titles[110] = "Level up Pet";
progress_titles[111] = "Reach evolve";
progress_titles[112] = "Reach rank";
progress_titles[113] = "Kill boss";
progress_titles[114] = "Kill big boss";
progress_titles[115] = "Unlock world";
progress_titles[116] = "Revive all time";


let tutorial_match_descriptions = [];
tutorial_match_descriptions[0] = "Match the 3 stone swords tiles!";
tutorial_match_descriptions[1] = "Match the 4 healing leaf tiles!";
tutorial_match_descriptions[2] = "Match the 5 stone swords tiles!";
tutorial_match_descriptions[3] = "Match the 3 fire elements tiles!";
tutorial_match_descriptions[4] = "Match the 3 water elements tiles!";
tutorial_match_descriptions[5] = "Match the 3 soil elememnts tiles!";
tutorial_match_descriptions[6] = "Match the 3 windy elements tiles!";

export default {

    getMainProgressTitles: function() {
        return progress_titles;
    },

    getProgressTitles: function() {
        return progress_des;
    },

    getMainTutorialTitles : function() {
        return tutorial_match_descriptions;
    },

   loadQuest : async function() {
       let questJson = require('@/quest-editor/assets/json/quest_data.json');
       let data = questJson;

       //on va mapper les donés
       // need to loop
       data.forEach(quest => {
           quest.ordered.forEach(progress => {
               progress.label = progress_titles[progress.progressTitleCode];
               progress.labelDescription = progress_des[progress.progressTitleCode];
               if (progress.eventName ==='tutorial') {
                   progress.label = tutorial_match_descriptions[progress.progressTitleCode];
               }
           })
       });

       return data;
   },

    loadDailyQuest : async function() {
        let questJson = require('@/quest-editor/assets/json/daily_quest_data.json');
        let data = questJson;

        data.forEach(quest => {
            quest.ordered.forEach(progress => {
                progress.label = progress_titles[progress.progressTitleCode];
                progress.labelDescription = progress_des[progress.progressTitleCode];
            })
        });

        return data;
    },
}
