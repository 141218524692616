import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};

export default {

    loadItemNameData : async function() {
        let data = await axios.get(NetworkUtils.HOST + '/item-name', config);
        if (data.data.length > 0) {
            data = data.data[0].list;
        } else {
            data = [];
        }
        return data;
    },

    loadWeaponNameData : async function() {
        let data = require('../assets/json/item-weapon-name-ref.json');
        return data;
    },

    loadPotionNameData : async function() {
        let listData = require('../assets/json/item-potion-name-ref.json');
        return listData;
    }
}