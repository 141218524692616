module.exports = {
 KILL_ENNEMY : 1,
 COLLECT_GOLD : 2,
 UNLOCK_SUPPORT : 3,
 REACH_HEROES_DPS : 4,
 KILL_N_BOSSES : 5,
 TAP_N_TIMES : 6,
 REACH_SUPPORT_N_LEVEL : 7,
 FAIRY_PRESENT : 8,
 USE_THUNDER : 9,
 REACH_STAGE : 10,
 GET_CRITICAL_HIT : 11,
 USE_PERK_DOOM : 12,
 KILL_GHOST : 14,
 KILL_MINIBOSS : 15,
 UNLOCK_HEROES : 16,
 USE_GIVE_ME_CASH: 17,
 USE_POWER_OF_HOLDING: 18,
 USE_MANA_PORTION: 19,
 REACH_FEAR_BUNNY_EARS: 29,
}
