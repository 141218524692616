import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};

export default {
    loadMultipleSessionConfig : async function() {
        let data = await axios.get(NetworkUtils.HOST + '/multiple-session', config);
        if (data.data.length > 0) {
            data = data.data[0].config;
            return data;
        } else {
            return  null;
        }
    },
}