<template>
    <v-app id="inspire">
        <v-app-bar
                app
                short
        >
            <v-toolbar-title>Hok Mok Editor</v-toolbar-title>
        </v-app-bar>

        <v-overlay :value="isLoading">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>

        </v-overlay>

        <v-main>
          <FooterLayout v-if="!isLoading" :pShowHome="false"></FooterLayout>
            <v-container>

                <v-row>
<!--                    <v-col-->
<!--                            cols="4"-->
<!--                    >-->
<!--                        <v-card height="200">-->
<!--                            <router-link :to="{name:'balance',params:{pBalanceBuilder:this.$store.state.balanceBuilder}}">Balance Editor</router-link>-->
<!--                        </v-card>-->
<!--                    </v-col>-->
                    <v-col
                            cols="3"
                    >
                        <v-card height="200">
                            <v-img
                                    height="150"
                                    :src="render_preview()"
                            ></v-img>
                            <v-card-text>
                                <router-link :to="{name:'skill',params:{pSkillList:this.$store.state.skillList, pUnlockSupportSetting:this.$store.state.unlockSupportSetting}}">Skill Editor</router-link>
                            </v-card-text>

                        </v-card>
                    </v-col>

                    <v-col
                            cols="3"
                    >
                        <v-card height="200">
                            <v-img
                                    height="150"
                                    :src="render_previewUnityServiceDashboard()"
                            ></v-img>
                            <v-card-text>
                                <router-link
                                        :to="{name:'unityServiceDashboard'}">
                                    Unity Service Dashboard
                                </router-link>
                            </v-card-text>

                        </v-card>
                    </v-col>


<!--                    <v-col-->
<!--                            cols="4"-->
<!--                    >-->
<!--                        <v-card height="200">-->
<!--                            <v-img-->
<!--                                    height="150"-->
<!--                                    :src="render_preview2()"-->
<!--                            ></v-img>-->
<!--                            <v-card-text>-->
<!--                                <router-link :to="{name:'quest',params:{pQuestList:this.$store.state.questList, pSkillList:this.$store.state.skillList, pIconList:this.iconList}}">Quest Editor</router-link>-->
<!--                            </v-card-text>-->

<!--                        </v-card>-->
<!--                    </v-col>-->
                </v-row>

                <v-row>
                    <v-col
                            cols="3"
                    >
                      <v-card height="200">
                        <v-img
                            height="150"
                            :src="render_previewInventory()"
                        ></v-img>

                        <!--                            <router-link to='inventory'>Inventory Editor</router-link>-->
                        <router-link :to="{name:'inventory',params:{pQuestList:this.$store.state.questList, pSkillList:this.$store.state.skillList, pIconList:this.iconList}}">Inventory</router-link>

                      </v-card>


                    </v-col>
<!--                    <v-col-->
<!--                            cols="4"-->
<!--                    >-->
<!--                        <v-card height="200">-->
<!--                            <v-img-->
<!--                                    height="150"-->
<!--                                    :src="render_preview3()"-->
<!--                            ></v-img>-->

<!--&lt;!&ndash;                            <router-link to='inventory'>Inventory Editor</router-link>&ndash;&gt;-->
<!--                          <router-link :to="{name:'itemManager'}">Item Manager</router-link>-->

<!--                        </v-card>-->
<!--                    </v-col>-->

                    <v-col
                        cols="3"
                    >
                        <v-card height="200">
                            <v-img
                                height="150"
                                :src="render_preview3()"
                            ></v-img>
                            <v-card-text>
                                <router-link :to="{name:'kpi-editor-mvc'}">KPI Editor MVC</router-link>
                            </v-card-text>

                        </v-card>
                    </v-col>




                </v-row>

                <v-row>
<!--                    <v-col-->
<!--                            cols="2"-->
<!--                    >-->
<!--                        <v-card height="200">-->
<!--                            <v-img-->
<!--                                    height="150"-->
<!--                                    :src="render_preview()"-->
<!--                            ></v-img>-->
<!--                            <v-card-text>-->
<!--                                <router-link :to="{name:'achievements',params:{pAchievementList:this.$store.state.achievementList}}">Achievements</router-link>-->
<!--                            </v-card-text>-->

<!--                        </v-card>-->
<!--                    </v-col>-->



                    <v-col
                        cols="3"
                    >
                        <v-card height="200">
                            <v-img
                                height="150"
                                :src="render_preview()"
                            ></v-img>
                            <v-card-text>
                                <router-link :to="{name:'achievement-simulation',params:{pAchievementList:this.$store.state.achievementList}}">Achievement-Sim</router-link>
                            </v-card-text>

                        </v-card>
                    </v-col>

<!--                    <v-col-->
<!--                            cols="2"-->
<!--                    >-->
<!--                        <v-card height="200">-->
<!--                            <v-card-text>-->
<!--                                <router-link :to="{name:'kpi'}">KPI</router-link>-->
<!--                            </v-card-text>-->

<!--                        </v-card>-->
<!--                    </v-col>-->

<!--                    <v-col-->
<!--                            cols="3"-->
<!--                    >-->
<!--                        <v-card height="200">-->
<!--                            <v-card-text>-->
<!--                                <router-link :to="{name:'kpi-editor'}">KPI Editor</router-link>-->
<!--                            </v-card-text>-->

<!--                        </v-card>-->
<!--                    </v-col>-->

                    <v-col
                        cols="3"
                    >
                        <v-card height="200">
                            <v-img
                                height="150"
                                :src="render_preview2()"
                            ></v-img>
                            <v-card-text>
                                <router-link
                                    :to="{name:'quest-editor',params:{pQuestList:this.$store.state.questList, pSkillList:this.$store.state.skillList, pIconList:this.iconList, pDailyQuestList: this.$store.state.dailyQuestList}}">
                                    New Quest Editor
                                </router-link>
                            </v-card-text>

                        </v-card>
                    </v-col>

<!--                    <v-col-->
<!--                        cols="3"-->
<!--                    >-->

<!--                        <v-btn v-on:click="doShowDev()">-->
<!--                            DEV-->
<!--                        </v-btn>-->

<!--                        <v-btn v-on:click="doExportAchievement()">-->
<!--                            Export Achievements-->
<!--                        </v-btn>-->

<!--                        <devContainer-->
<!--                            :modal="this.showDev"-->
<!--                            :devData="this.$store.state.heroSkillList"-->
<!--                            @close="showDev=false"-->
<!--                            @saveSkillList="onSaveSkillList"-->
<!--                        />-->

<!--                    </v-col>-->
                </v-row>
                 <router-view :key="$route.fullPath+this.$store.state.balanceBuilderVersion"> </router-view>
            </v-container>
        </v-main>



    </v-app>
</template>

<script>

    import supportLoad from './support-load.js';

    import newIcons from './skill-icons-load.js';
    // import devContainer from './components/DevContainer.vue';

    import questLoader from './dao/QuestLoader.js';
    import achievementLoader from './dao/AchievementLoader.js'
    import heroSkillLoader from './dao/HeroSkillLoader.js'
    import ratiosLoader from './dao/RatiosLoader.js';
    import itemNameDataLoader from './item-editor/dao/ItemNameRefLoader';

    //Item Editor
    import itemLoader from './item-editor/dao/ItemApi.js';
    import itemStatusLoader from './item-editor/dao/ItemStatusDataLoader.js';

    import FooterLayout from './components/commons/FooterLayout';
    import GamePlayRatioApdater from "./kpi-editor/classes/GamePlayRatioApdater";

    import  unlockSupportSettingLoader from "./kpi-editor/dao/UnlockSupportSettingLoader";

    import environmentConfigLoader from "./unity-service-dashboard/dao/EnvironmentSettingRefLoader";
    import unityServiceItemUtils from "./unity-service-dashboard/Utils/UnityServiceItemUtils";
    import gameVeresionConfigLoader from "./unity-service-dashboard/dao/GameVersionConfigRefLoader";
    import simulatorItemRequireLoader from "./kpi-editor/dao/SimulatorItemRequireLoader"
    import automateItemCreateDataLoader from "./item-editor/dao/AutomateItemCreateDataLoader";
    import {GlobalEvents, EVENT_UPDATED_EVIRONMENT_CONFIG} from "@/item-editor/events/GlobalEvents";
    import multipleSessionConfigLoader from "./unity-service-dashboard/dao/MultipleSessionConfigLoader";
    import enemyDataRefLoader from "./kpi-editor/dao/EnemyDataRefLoader";
    import charactersDataRefLoader from "./kpi-editor/dao/CharactersDataRefLoader";

    //import BalanceBuilder from './BalanceBuilder.js';

    export default {

        data () {
            return {
                iconList : [],
                showDev: false,
                isLoading: true,

            }
        },

      beforeMount: async function() {

          let ratiosValue = await ratiosLoader.loadData();
          this.$store.commit(this.$store.state.commitActions.INIT_RATIOS,ratiosValue);

          /*not use more
          let ratioGamePlay = await  ratiosLoader.loadGamePlayRatioData();
          this.$store.commit(this.$store.state.commitActions.GAMEPLAY_RATIOS, ratioGamePlay);*/

          //convert from ratio editor to ratio like game play
          let ratioLikeGamePlay = GamePlayRatioApdater.getGamePlayRatio(ratiosValue);

          let enemysDataPayload = await enemyDataRefLoader.loadEnemyData();
          this.$store.commit(this.$store.state.commitActions.INIT_ENEMY_DATA, enemysDataPayload);

          let charactersData = await charactersDataRefLoader.loadCharactersData();
          this.$store.commit(this.$store.state.commitActions.INIT_CHARACTERS_DATA, charactersData);

          let unlockSupportSetting = await unlockSupportSettingLoader.loadData();
          this.$store.commit(this.$store.state.commitActions.INIT_SUPPORT_UNLOCK, unlockSupportSetting);

          let multipleSessionConfig = await multipleSessionConfigLoader.loadMultipleSessionConfig();
          this.$store.commit(this.$store.state.commitActions.INIT_MULTIPLE_SESSION, multipleSessionConfig);

          this.$store.commit(this.$store.state.commitActions.INIT_GAMEPLAY_SERVICE_DATA, ratioLikeGamePlay);

            let skillList = await supportLoad.loadSkillData();

            //enable to reset skill list
            //skillList = null;

            let newIconsList = await newIcons.initIconList();
            this.iconList = newIconsList;

            this.$store.commit(this.$store.state.commitActions.UPDATE_SKILL_LIST, skillList);
           //console.log(this.$store.state.skillList);


            let questList = await questLoader.loadQuest();
            this.$store.commit(this.$store.state.commitActions.UPDATE_QUEST_LIST,questList);

            let dailyQuestList = await  questLoader.loadDailyQuest();
            this.$store.commit(this.$store.state.commitActions.UPDATE_DAILY_QUEST_LIST, dailyQuestList);

            let progressTitles = questLoader.getProgressTitles();
            this.$store.commit(this.$store.state.commitActions.INIT_PROGRESS_TITLE,progressTitles);

            let mainProgressTitles = questLoader.getMainProgressTitles();
            this.$store.commit(this.$store.state.commitActions.INIT_PROGRESS_MAIN_TiTLE,mainProgressTitles);

            let tutorialProgressTitles = questLoader.getMainTutorialTitles();
            this.$store.commit(this.$store.state.commitActions.INIT_TUTORIAL_TITLE,tutorialProgressTitles);

            let achievementList =  await achievementLoader.loadData();
            this.$store.commit(this.$store.state.commitActions.INIT_ACHIEVEMENTS,achievementList);

            let heroSkillList = await heroSkillLoader.loadData();
            this.$store.commit(this.$store.state.commitActions.INIT_HERO_SKILL,heroSkillList);

            //console.log('HEY ON EST LA AUSSI');
            //console.log(this.$store.state.petList);

            // let balance = new BalanceBuilder(
            //     this.$store.state.skillList,
            //     this.$store.state.achievementList,
            //     this.$store.state.petList,
            // );

            let ugsSetting = await environmentConfigLoader.loadUGSSetting();
            this.$store.commit(this.$store.state.commitActions.INIT_UGSSETTING, ugsSetting);

            //Item Editor
            //let itemList = await itemLoader.getItems();
            let itemGroupList = await itemLoader.getItemGroups();
            itemGroupList = await unityServiceItemUtils.applyEnvironmentPushHistory(itemGroupList, this.$store.state.ugsSetting.environmentList);
            this.$store.commit(this.$store.state.commitActions.INIT_ITEMS, itemGroupList);
            let itemStatusList = await itemStatusLoader.loadData();
            this.$store.commit(this.$store.state.commitActions.INIT_ITEM_STATUS, itemStatusList);

            let gameversion = await gameVeresionConfigLoader.loadGameVersion();
            this.$store.commit(this.$store.state.commitActions.INIT_GAMEVERSION, gameversion);

            let itemNames = await itemNameDataLoader.loadItemNameData();
            let weaponNames = await itemNameDataLoader.loadWeaponNameData();
            let potionNames = await itemNameDataLoader.loadPotionNameData();
            this.$store.commit(this.$store.state.commitActions.INIT_ITEM_NAME, {itemNames, weaponNames, potionNames});

            await this.loadSimulatorItemRequireData();
            await this.loadAutomateConfigData();

            //this.$store.commit(this.$store.state.commitActions.INIT_BALANCE);

            //console.log('HOME debug balance from store ' + this.$store.state.balanceBuilder);
            this.isLoading = false;

        },

        components: {
          // 'devContainer':devContainer,
          FooterLayout: FooterLayout
        },
        created() {
            GlobalEvents.$on(EVENT_UPDATED_EVIRONMENT_CONFIG, () => {
                this.loadSimulatorItemRequireData();
                this.loadAutomateConfigData();
            });
        },
        methods: {

            onSaveSkillList(eventData) {
                //console.log('onSaveSkillList');
                //console.log(eventData.skillList);
              console.log(eventData);
            },

            doExportAchievement () {
                //how to export the achievements.
                //console.log('connard');
                //console.log(this.$store.state.achievementList);


                const data = JSON.stringify(this.$store.state.achievementList)
                const blob = new Blob([data], {type: 'text/plain'})
                const e = document.createEvent('MouseEvents'),
                    a = document.createElement('a');
                a.download = "test.json";
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
                e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
            },

            doShowDev() {
              //console.log('click on dev');
              this.showDev = true;
            },
            render_preview() {
                return require('./assets/icon-preview.png')
            },
            render_preview2() {

                return require('./assets/preview.png')
            },
            render_preview3() {
                return require('./assets/preview3.png')
            },
          render_previewInventory() {
            return require('./assets/preview-inventory.png')
          },
            render_previewUnityServiceDashboard() {
                return require('./assets/unity-game-service.jpg')
            },

            async loadAutomateConfigData(){
                let ugsSetting = this.$store.state.ugsSetting;
                let automateItemCreateData = await automateItemCreateDataLoader.loadData(ugsSetting.environmentId);
                this.$store.commit(this.$store.state.commitActions.INIT_AUTOMATE_ITEM_CREATE_DATA, automateItemCreateData);
            },

            async loadSimulatorItemRequireData(){
                let ugsSetting = this.$store.state.ugsSetting;
                let simulatorItemRequireData = await simulatorItemRequireLoader.loadData(ugsSetting.environmentId);
                this.$store.commit(this.$store.state.commitActions.INIT_SIMULATOR_ITEM_REQUIRE, simulatorItemRequireData);
            },
        }
    }
</script>



