import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../store";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};

export default {

    loadData: async function () {

        let data = await axios.get(NetworkUtils.HOST + '/ratios?_limit=1&_sort=id:DESC', config);

        if (data.data.length > 0) {
            data = data.data[0].ratios;
        } else {
            //console.log('ERROR WHILE LOADING RATIOS');
        }
        return data;
    },

    loadGamePlayRatioData: async function () {
        let data = await axios.get('https://prod.hokmok.com/editorratioconfig');
        return data.data.data.json;
    }
}
