//import axios from "axios";
//import NetworkUtils from '../NetworkUtils.js'
import SMPAchievement from "../GameplayAchievement";

export default {

    saveData : async function(achievement) {
        //TODO something with the achievement.
        if (achievement._id) {
            //toDO make a post
        }else {
            //todo make a put with the one. that ist.
        }
    },

    utilBuildAchievementGameplay: function (achievementList) {
        let list = [];
        achievementList.forEach(a => {
            let achievement = new SMPAchievement(a);
            list.push(achievement);
        })
        return list;
    },

    utilFindAchievementMatchingId : function(achievmentList,achievmentId) {
        let filtered =  achievmentList.filter(e => e.id === achievmentId);
        if (filtered.length > 0) {
            return filtered[0];
        }else {
            return null;
        }
    },

    loadData : async function() {
        let achievementJson = require('../../legacy/AchievementData-15-03-2023.json');
        let data = {data: achievementJson};
        return data.data;
    }
}
