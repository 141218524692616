import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store";
const authorize =  `Bearer ${store.getters.getToken}`;

const config = {
    headers: { Authorization: authorize }
};

export default {

    loadCharactersData : async function() {
        let data = await axios.get(NetworkUtils.HOST + '/characters-data', config);
        let heroesData = [];
        let supportersData = [];
        let petsData = [];
        if (data.data.length > 0) {
            heroesData = data.data[0].heroesData;
            supportersData = data.data[0].supportersData;
            petsData = data.data[0].petsData;
        }

        if(heroesData.length < 1){
            heroesData = await this.loadHeroesDataLocal();
            await this.pushHeroesData(heroesData);
        }

        if(supportersData.length < 1){
            supportersData = await this.loadSupportersDataLocal();
            await this.pushSupportersData(supportersData);
        }

        if(petsData.length < 1){
            petsData = await this.loadPetsDataLocal();
            await this.pushPetsData(petsData);
        }

        return {
            heroesData: heroesData,
            supportersData: supportersData,
            petsData: petsData
        };
    },

    loadHeroesDataLocal : async function() {
        let data = require('../assets/json/HeroesData.json');
        return data;
    },

    loadSupportersDataLocal : async function() {
        let data = require('../assets/json/SupportData.json');
        return data;
    },

    loadPetsDataLocal : async function() {
        let data = require('../assets/json/petsdata.json');
        return data;
    },

    async pushHeroesData(data) {
        let persistedList = await axios.get(NetworkUtils.HOST + "/characters-data", config);
        if (persistedList.data && persistedList.data.length > 0) {
            await axios.put(NetworkUtils.HOST + "/characters-data/" + persistedList.data[0]._id, {heroesData: data}, config);

        } else {
            await axios.post(NetworkUtils.HOST + "/characters-data", {heroesData: data},config);
        }
    },

    async pushSupportersData(data) {
        let persistedList = await axios.get(NetworkUtils.HOST + "/characters-data", config);
        if (persistedList.data && persistedList.data.length > 0) {
            await axios.put(NetworkUtils.HOST + "/characters-data/" + persistedList.data[0]._id, {supportersData: data}, config);

        } else {
            await axios.post(NetworkUtils.HOST + "/characters-data", {supportersData: data},config);
        }
    },

    async pushPetsData(data) {
        let persistedList = await axios.get(NetworkUtils.HOST + "/characters-data", config);
        if (persistedList.data && persistedList.data.length > 0) {
            await axios.put(NetworkUtils.HOST + "/characters-data/" + persistedList.data[0]._id, {petsData: data}, config);

        } else {
            await axios.post(NetworkUtils.HOST + "/characters-data", {petsData: data},config);
        }
    },
}